<template>
  <div class="app-container">
    <van-nav-bar
        :fixed="true"
        :placeholder="true"
        title="客户地址"
        left-text="返回"
        right-text="保存"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight('addressInfo')"
    />
    <div class="BulkCrmsCusAddressAddAdd">
      <el-row>
        <el-col :span="24">
          <el-form ref="addressInfo" :model="addressInfo" :rules="rules" label-width="80px" class="demo-ruleForm">
            <van-row>
              <van-field
                  readonly
                  clickable
                  label="省市："
                  :value="addressInfo.selPrCyValue"
                  :rules="rules.prCyValue"
                  placeholder="选择省市"
                  @click="addressInfo.showPrCyPicker = true"
              />
              <van-popup v-model="addressInfo.showPrCyPicker" round position="bottom">
                <van-tree-select
                    :items="addressInfo.prCyItems"
                    :active-id.sync="addressInfo.prCyActiveId"
                    :main-active-index.sync="addressInfo.prCyActiveIndex"
                    @click-item="onClickItem"
                    @click-nav="onClickNav"
                />
              </van-popup>
            </van-row>
            <el-form-item label="详细地址" prop="detail">
              <el-input v-model="addressInfo.detail" placeholder="请输入详细地址"/>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>

export default {
  name: "BulkCrmsCusAddressAddLs",
  components: {},
  data() {
    return {
      btName: '',
      // 位置信息
      addressInfo: {
        addressId: '',
        detail: '',
        selPrCyValue: '',
        showPrCyPicker: false,
        prCyItems: [],
        prCyActiveId: '',
        prCyActiveIndex: -1,
      },
      rules: {
        detail: [
          {required: true, message: '请填写详细地址', trigger: 'blur'},
        ],
        prCyValue: [
          {required: true, message: '请选择省市！'},
        ]
      },
    };
  },
  mounted() {
    this.init()
    this.addressInfo = {
      addressId: '',
      detail: '',
      selPrCyValue: '',
      showPrCyPicker: false,
      prCyItems: [],
      prCyActiveId: '',
      prCyActiveIndex: -1,
    }
  },
  methods: {
    init() {
      //省市下拉数据获取
      this.$ajax.post('/api/ajax/cusAddress/getPrCyItemsInfo.json', {cusId: 'ti01'}).then(res => {
        if (res.data != null) {
          this.addressInfo.prCyItems = res.data.prCyItems
          if (this.addressInfo.prCyItems && this.addressInfo.prCyItems.length > 0) {
            //默认选择
            this.addressInfo.prCyActiveIndex = 0
            this.addressInfo.prCyActiveId = 0
            let prCyActiveIndexText = "福建省"
            let prCyActiveIdText = ""
            this.addressInfo.prCyItems.forEach((value, index) => {
              if (value.text == prCyActiveIndexText) {
                this.addressInfo.prCyActiveIndex = index
                this.addressInfo.prCyActiveIndexId = value.text
                value.children.forEach((v, i) => {
                  if (i == 0) {
                    this.addressInfo.prCyActiveId = v.id
                    prCyActiveIdText = v.text
                    return false
                  }
                })
                return false;
              }
            })
            if (this.addressInfo.prCyActiveIndex > -1) {
              this.addressInfo.selPrCyValue = prCyActiveIndexText + " / " + prCyActiveIdText
            }
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    onClickLeft() {
      //返回上一级页面
      this.$router.push({path: '/BulkCrmsCusAddressListLs/' + this.$route.params.name})
    },
    onClickRight(formName) {
      console.log(this.addressInfo)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$ajax.post('/api/ajax/cusAddress/saveBulkCrmsLsCusAddress.json',
              {
                cusId: this.$route.params.name,
                province: this.addressInfo.selPrCyValue.split('/')[0].replace(/\s*/g, ''),
                city: this.addressInfo.selPrCyValue.split('/')[1].replace(/\s*/g, ''),
                detail: this.addressInfo.detail,
                addressId: this.addressInfo.addressId,
                crePers: this.$utils.getUser(),
              }).then(res => {
            if (res.status == 200) {
              this.$message({
                showClose: true,
                message: '添加成功',
                type: 'success'
              });
            }
          }).catch(err => {
            console.log(err)
            this.$message.error('添加失败');
          })
          setTimeout(() => {
            this.$router.replace({path: '/BulkCrmsCusAddressListLs/' + this.$route.params.name})
          }, 200);
        } else {
          console.log('未通过校验!!');
          return false;
        }
      });
    },
    onClickItem(data) {
      console.log(data, this.addressInfo.prCyActiveId, this.addressInfo.prCyActiveIndex);
      this.prCyActiveId = data.id

      let prCyActiveIndexText = ""
      let prCyActiveIdText = ""
      this.addressInfo.prCyItems.forEach((value, index) => {
        if (index == this.addressInfo.prCyActiveIndex) {
          this.addressInfo.prCyActiveIndexId = value.text
          prCyActiveIndexText = value.text

          value.children.forEach((v) => {
            if (v.id == this.addressInfo.prCyActiveId) {
              prCyActiveIdText = v.text
              return false
            }
          })
          return false;
        }
      })

      if (prCyActiveIndexText.length > 0 && prCyActiveIdText.length > 0) {
        this.addressInfo.selPrCyValue = prCyActiveIndexText + " / " + prCyActiveIdText
      }

      this.addressInfo.showPrCyPicker = false
    },
    onClickNav(index) {
      console.log(index);
      this.addressInfo.prCyActiveIndex = index
    },
  },
}
</script>

<style scoped>
.app-container {
  background: white;
}

.BulkCrmsCusAddressAdd {
  margin: 15px 25px 15px 15px;
  background: white;
}

.detail {
  padding: 5px;
}

.van-button {
  width: 220px;
  height: 35px;
  border-radius: 5px;
  font-weight: bold;
  font-family: 新宋体;
}

</style>

<style>

</style>
